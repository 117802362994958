import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, PageTitle, Box, Button } from "../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/Seo/Seo";
import { getProperty } from "../utils/helperFn";
import SupportSectionSetup from "../sections/SupportSectionSetup/SupportSectionSetup"
import { navigate } from "gatsby";
import modem1 from './../assets/image/png/modem-1.png'
import modem2 from './../assets/image/png/modem-2.png'

import HeroSetup from "../components/Rebrand/Hero/HeroSetup";

import router1 from './../assets/image/rebrand/png/router/router-1.png'
import router2 from './../assets/image/rebrand/png/router/router-2.png'
import router3 from './../assets/image/rebrand/png/router/router-3.png'
import router4 from './../assets/image/rebrand/png/router/router-4.png'
import router5 from './../assets/image/rebrand/png/router/router-5.png'

import router1Pdf from './../assets/pdf/router-1.pdf'
import router2Pdf from './../assets/pdf/router-2.pdf'
import router3Pdf from './../assets/pdf/router-3.pdf'
import router4Pdf from './../assets/pdf/router-4.pdf'
import router5Pdf from './../assets/pdf/router-5.pdf'

const GetStarted = () => {

    const data = useStaticQuery(graphql`
        query getstartedPageQuery {
            strapiSupportSectionSetup {
                section_title
                section_text
                feature1_title
                feature1_text
                feature1_icon
                feature2_title
                feature2_text
                feature2_icon  
          }
          strapiSetupPage {
            title
            text_1
            text_2
          }
        }
    `)

    const supportSectionData = getProperty(data, 'strapiSupportSectionSetup')
    const setupPageData = getProperty(data, 'strapiSetupPage')

    return (
        <>
            <Seo page="setup" />
            <HeroSetup />
            <Section className="pb-0">
                <Container>
                <Row className="text-center flex-end">
                        <Col lg={4} className="mb-5">
                            <img src={router1} width={140}/>
                            <a href={router1Pdf} target="_blank" className="d-block">
                                <Button className="medium-button blue-bg sign-up mt-4 mb-4">
                                    Download User Guide
                                </Button>
                            </a>
                            <div>
                                <a href="https://support.sagemcom.com/en/gateways/fast-5598-yf" target="_blank">Safety Documentation ›</a>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-5">
                            <img src={router2} width={180}/>
                            <a href={router2Pdf} target="_blank" className="d-block">
                                <Button className="medium-button blue-bg sign-up mt-4 mb-4">
                                    Download User Guide
                                </Button>
                            </a>
                            <div>
                                <a href="https://support.sagemcom.com/en/gateways/fast5599-youfibre" target="_blank">Safety Documentation ›</a>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-5">
                            <img src={router3} width={180}/>
                            <a href={router3Pdf} target="_blank" className="d-block">
                                <Button className="medium-button blue-bg sign-up mt-4 mb-4">
                                    Download User Guide
                                </Button>
                            </a>
                            <div>
                                <a href="https://support.sagemcom.com/en/gateways/fast-281-yf-db" target="_blank">Safety Documentation ›</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pb-5">
                        <Col sm={12}>
                            <p>
                                If you joined YouFibre in early 2025 you may have our Eero or Asus devices, featured below:
                            </p>
                        </Col>
                    </Row>
                    <Row className="text-center flex-end pb-5">
                        <Col lg={4} className="mb-5">
                            <img src={router4} />
                            <a href={router4Pdf} target="_blank" className="d-block">
                                <Button className="medium-button blue-bg sign-up mt-4 mb-4">
                                    Download User Guide
                                </Button>
                            </a>
                            <div>
                                <a href="https://eero.com/legal/compliance?lang=en-ca&srsltid=AfmBOooVXUKq6_aLO2LTquf8GIEo0h8jha5xr1vc8uSMhauac2Jwnc4T" target="_blank">Safety Documentation ›</a>
                            </div>
                        </Col>
                        <Col lg={4} className="mb-5">
                            <img src={router5} />
                            <a href={router5Pdf} target="_blank" className="d-block">
                                <Button className="medium-button blue-bg sign-up mt-4 mb-4">
                                    Download User Guide
                                </Button>
                            </a>
                            <div>
                                <a href="https://fcc.report/FCC-ID/GZ5NVG578HLXV2/6140289.pdf" target="_blank">Safety Documentation ›</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};
export default GetStarted;
